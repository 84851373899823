import React, { Component } from 'react';


export default class CallToActionTile extends Component {
    render(){
        let { title, content, children, heromode } = this.props;

        if(heromode){
            return (
                <div className="call-to-action-container">
                    <h2 className="py-4 support">{content}</h2>
                    {children}
                </div>
            )
            }        
        else{
            return (
                <div className="call-to-action-container">
                    <h2>{title}</h2>
                    <hr></hr>
                    <p className="py-4">{content}</p>
                    {children}
                </div>
            )
        };
    }    
}