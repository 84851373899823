import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router } from 'react-router-dom'

import * as serviceWorker from './serviceWorker';

import Home from './pages/home';
import ReflectionsOfTheFallen from './pages/reflectionsOfTheFallen';
import muster50thGallery from './pages/muster50thgallery';

import 'bootstrap/dist/css/bootstrap.css';
import './styles/css/index.css';

import './polyfills.js';
import ReactGA from 'react-ga4';
if (window.location.host === 'musterlive.aggienetwork.com'){
  ReactGA.initialize("G-BL6976S8JL");
} else {
  ReactGA.initialize([{trackingId:"G-BL6976S8JL", testMode: true}]); //test mode on all sites that aren't production.
}

const routing = (
  <Router>
    <div>
      <Route exact path="/" component={Home} />
      <Route exact path="/reflections/:hashTag?" component={ReflectionsOfTheFallen} />
      <Route exact path="/muster50thgallery" component={muster50thGallery} />
    </div>
  </Router>
)

ReactDOM.render(routing, document.getElementById('root'))

serviceWorker.unregister();
