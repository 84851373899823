import React, { useState } from 'react';
import ringLogo from '../../assets/afsringlogo-maroon.svg'
import {
    Collapse,
    Navbar,
    NavbarToggler,
 //   Col,
 //   Row,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';


const Navigation = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (

        <div>
            
            <Navbar className="navigation-container w-100" color="faded" light expand="md">

                
                <a class="logolink" href="/">
                    <div class="headerlogo flexcontainer">
                        <img src={ringLogo} alt="The Association of Former Students - Ring logo" />
                        <div class="logoseparator"></div>
                        <div class="logotext">Aggie Muster</div>
                    </div>
                </a>
                <NavbarToggler onClick={toggle} />
                <Collapse className="dropdown-color" isOpen={isOpen} navbar>

                   
                    <Nav navbar className="w-100 navbar-right">
                         <NavItem className="nav-links" >
                            <NavLink href="/reflections">Muster Reflections</NavLink>
                        </NavItem>
                        <NavItem className="nav-links">
                            <NavLink href="https://www.aggienetwork.com/muster/search.aspx" target="_blank" rel="noopener noreferrer">Musters Worldwide</NavLink>
                        </NavItem>
                        <NavItem className="nav-links">
                            <NavLink href="https://www.aggienetwork.com/muster/rollcall/" target="_blank" rel="noopener noreferrer">Roll Call</NavLink>
                        </NavItem>
                        <NavItem className="nav-links">
                            <NavLink href="http://tx.ag/WhatIsMuster" target="_blank" rel="noopener noreferrer">What is Muster?</NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>

        </div>
    );
}

export default Navigation;

