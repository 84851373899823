import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import associationLogo from '../../assets/logo-association.png';

import inventiveHeart from '../../assets/inventive-heart.png';

export default class Footer extends Component {

    render() {
        let footerClass = this.props.footerClass;

        return (
            <Row className={footerClass}>
                <Col xs="12" md="6">
                    <div className="footer-copyright">
                        <p><a href="https://www.aggienetwork.com/privacy/" target="_blank" rel="noopener noreferrer">Security & Privacy Policy</a></p>
                        <p>&copy; 2024 The Association of Former Students of Texas A&M University. All Rights Reserved.</p>
                        <p>Made with <img src={inventiveHeart} alt="Made with Love by Inventive"/> by <a href="https://inventive.io/" target="_blank" rel="noopener noreferrer">Inventive</a></p>
                    </div>
                </Col>
                <Col xs="12" md="6">
                    <div className="footer-logos">
                        
                        <div className="footer-logo"><a href="https://www.aggienetwork.com/" target="_blank" rel="noopener noreferrer"><img src={associationLogo} alt="The Association of Former Students of Texas A&M University"/></a></div>
                        
                    </div>
                </Col>
                {/* <Col xs="12">
                    <div className="footer-inv">
                        <p>Made with ♥ by <a href="https://inventive.io/" target="_blank" rel="noopener noreferrer">Inventive</a></p>
                    </div>
                </Col> */}
            </Row>
        );
    }
}