import React, { Component } from 'react';

export default class VideoTile extends Component {


    render() {
        let { stream } = this.props;
        let interceptIframeClick = (e) => {
            e.stopPropagation();
            this.props.setCurrentVideo();
        };

        let titleParts = stream.title ? stream.title.split("|") : "";

        return (
            <div className="video-tile">
                <div className="video-tile-button" onClick={this.props.setCurrentVideo}>
                    <div className="video-container">

                        <iframe className="video" style={{ borderRadius: '4px 4px 0px 0px' }} width="300" height="163" src={`${stream.encode}`} title={stream.title} frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" data-ready="true"></iframe>
                        <div className="overlay" onClick={interceptIframeClick}></div>
                    </div>
                    {stream.isLive === "Yes" ? <div className="pill red">LIVE</div> : false}
                    <div className="video-tile-content align-left">
                        <div className="w-100 title">

                            {titleParts.map(t => <p key={t}>{t}</p>)}

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}