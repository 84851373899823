import React, { Component } from 'react';

import { Row, Col } from 'reactstrap';
import candleStick from '../../assets/candle-stick.png'


export default class HereMessageTile extends Component {
    render(){
        let { heading, subheading, hashTags, onHashTagSelect } = this.props;

        return (
            <Row className="here-message-tile-container">
                <Col xs="2" md="2" lg="2" xl="2" className="d-flex justify-content-center align-items-center pl-4">
                    <div className="candle-stick-container">
                        <img alt="Candle Stick" src={candleStick}/>
                    </div>
                </Col>
                <Col xs="10" md="10" lg="10" xl="10" className="here-message-tile">
                    <h3>{heading}. "Here."</h3>
                    <p>{subheading}</p>
                    {hashTags && <span style={{cursor: "pointer"}} onClick={() => onHashTagSelect(hashTags,heading)}>{hashTags}</span>}
                </Col>
            </Row>
        )
    }
}