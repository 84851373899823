import React, { Component } from 'react';

import Select from 'react-select';

import { Form, FormGroup, Label, Input } from 'reactstrap';
import PrimaryButton from './Button';
import rotateIcon from '../../assets/rotate.png';

export default class BasicForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showForm: true,
            errors: "",
            isSubmitting: false,
            rotation: 0
        }

        this.formElement = React.createRef();
        this.populateImage = this.populateImage.bind(this);
        this.rotateImageCounterClockwise = this.rotateImageCounterClockwise.bind(this);
        this.rotateImageClockwise = this.rotateImageClockwise.bind(this);
    }

    componentDidMount() {
        let registeredUser = this.props.registeredUser;
        if (registeredUser) {
            this.setState({ showForm: false })
        }
    }

    componentWillReceiveProps(newProps) {
        let registeredUser = newProps.registeredUser;
        if (registeredUser) {
            // Weird race condition here with toggleForm after register causes modal to stay opened sometimes.
            setTimeout(() => {
                this.setState({ showForm: false })
            }, 100);
        } else {
            this.setState({ showForm: true });
        }

    }

    toggleForm = () => {
        this.setState({ showForm: !this.state.showForm });
    }

    async submitForm(e) {
        e.preventDefault();

        if (this.state.isSubmitting) {
            return;
        }

        this.setState({ isSubmitting: true });
        try {
            const formData = new FormData(this.formElement.current);
            let validationErrors = undefined;

            // ensure a validation method was provided
            if (this.props.performValidation) {
                validationErrors = this.props.performValidation(formData);
                this.setState({ errors: validationErrors });
            }

            // if there are no validation errors, proceed with submit
            if (!validationErrors) {

                // dismiss the modal if we are successful
                const submitResult = await this.props.onSubmitForm(formData);
                if (!submitResult) {
                    if (this.props.toggleModal) { this.props.toggleModal(); }
                    return this.toggleForm();
                }

            }
        }
        finally {
            this.setState({ isSubmitting: false });
        }


    }

    handleChange = (selectedOption, action) => {

        if (action.action === "select-option") {
            let formInputs = this.formElement.current.getElementsByTagName('input');
            let newValue = JSON.stringify(selectedOption);

            for (let i = 0; i < formInputs.length; i++) {
                let input = formInputs[i];
                if (input.name === "hereFor") {
                    input.value = newValue;
                } else if (input.name === "hashTags") {
                    input.value = selectedOption.defaultHashTag;
                }
            }
        }

    };

    populateImage = (e) => {
        this.setState({ imageData: URL.createObjectURL(e.target.files[0]) });
    }
    rotateImageCounterClockwise = (e) => {
        let { rotation } = this.state;
        e.preventDefault();
        this.setState({ rotation: rotation - 90 });
    }
    rotateImageClockwise = (e) => {
        let { rotation } = this.state;
        e.preventDefault();
        this.setState({ rotation: rotation + 90 });
    }

    render() {
        let { title, content, selectOptions, uppercase, fields, rememberMe, formAction, formMethod, submitBtnText, children, containerClass, formClass, hrWidth, hrClass, submitBtn } = this.props;
        let { showForm, imageData, rotation } = this.state;
        const optionLabel = (inputValue) => {
            if (typeof inputValue.classOf === "number") {

                if (inputValue.classOf < 100) {
                    return `${inputValue.name} '${inputValue.classOf.toString().padStart(2, 0)}`;
                } else {
                    return `${inputValue.name}, Class of ${inputValue.classOf}`;
                }
            } else {
                return `${inputValue.name}`;
            }
        }

        const optionValue = (inputValue) => {
            return `${inputValue.id}`;
        }

        return (
            <div className={containerClass}>
                <Form innerRef={this.formElement} onSubmit={(e) => this.submitForm(e)} className={`${formClass}  ${showForm ? "show" : "hide"}`} action={formAction ? formAction : "POST"} method={formMethod}>
                    <div className="text-center flex-column align-items-center d-flex">
                        <h2 className="primary-color secondary">{title}</h2>
                        <hr className={hrClass} style={{ width: hrWidth }}></hr>
                        <p className="py-4">{content}</p>
                    </div>

                    {fields.map((field, index) => {
                        return (

                            <FormGroup key={index} className={field.style}>
                                {field.inputType && field.inputType === "select" ?
                                    <Select
                                        className="dynamic-select-container"
                                        classNamePrefix="dynamic-select"
                                        placeholder="I am Here for..."
                                        options={selectOptions}
                                        isSearchable
                                        getOptionLabel={optionLabel}
                                        getOptionValue={optionValue}
                                        defaultOptions
                                        required={field.required}
                                        onChange={this.handleChange}
                                    /> : field.type === "file" ? <>

                                        {imageData && <div>
                                            <img src={imageData} alt="uploaded preview" style={{ maxWidth: "400px", transform: `rotate(${rotation}deg)` }} />
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <button onClick={this.rotateImageCounterClockwise} style={{ border: "none" }}><img src={rotateIcon} title="Rotate counter-clockwise" alt="Rotate counter-clockwise" style={{ border: "none", backgroundColor: "white", width: "30px", transform: "scaleX(-1)" }} /></button>
                                                <button onClick={this.rotateImageClockwise} style={{ border: "none" }}><img src={rotateIcon} title="Rotate clockwise" alt="Rotate clockwise" style={{ border: "none", backgroundColor: "white", width: "30px", }} /></button>
                                            </div>
                                            <input type="hidden" name="rotation" value={rotation} />
                                        </div>}
                                        <Input id={field.id}
                                            className={`input ${field.hidden ? 'd-none' : ''}`}
                                            type={field.type}
                                            name={field.name}
                                            placeholder={field.placeholder}
                                            defaultValue={field.defaultValue}
                                            required={field.required}
                                            maxLength={field.maxLength}
                                            accept="image/*"
                                            onChange={this.populateImage}
                                        /></>
                                        : <Input id={field.id}
                                            className={`input ${field.hidden ? 'd-none' : ''}`}
                                            type={field.type}
                                            name={field.name}
                                            placeholder={field.placeholder}
                                            defaultValue={field.defaultValue}
                                            required={field.required}
                                            maxLength={field.maxLength}
                                        />
                                }
                            </FormGroup>
                        )
                    })}

                    {
                        rememberMe ?
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" id="rememberMe" name="rememberMe" />{' '}
                                    Remember Me
                                </Label>
                            </FormGroup>
                            : ''
                    }

                    {this.state.errors &&
                        <div className="text-center flex-column align-items-center d-flex">
                            <div className="alert alert-warning">
                                {this.state.errors}
                            </div>
                        </div>
                    }

                    {submitBtn ? submitBtn : <PrimaryButton uppercase={uppercase} type="submit" margin="mt-4" size="large w-100">{submitBtnText}</PrimaryButton>}
                </Form>


                {children}
            </div>
        )
    }
}