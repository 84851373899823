import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';


export default class PrimaryButton extends Component {
    render(){
        let { children, to, action, size, margin, uppercase } = this.props;
        return (
            <>
                { to ? <Link className={`primary-button px-5 py-2 ${size} ${uppercase ? "uppercase" : ''} ${margin}`} to={to}>{children}</Link> : <Button onClick={action} className={`primary-button ${size} ${uppercase ? "uppercase" : ''} ${margin}`}>{children}</Button> }
            </>
        )
    }
}