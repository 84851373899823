import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Navigation, PrimaryButton, NavTabs, VideoTile, HereMessageTile, ReflectionTile, DefaultVideo, BasicForm, CallToActionTile, ModalForm, MasonryLayout, Footer, SupportMuster } from '../components/index';
import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';

import candleStickLight from '../assets/candle-stick-light.png';
import candleStickDark from '../assets/candle-stick-dark.png';
import candleStick from '../assets/candle-stick.png'

import frenchHornLight from '../assets/french-horn-light.png';
import frenchHornDark from '../assets/french-horn-dark.png';
import searchIcon from '../assets/search.png';

import axios from 'axios';
import hereNames from '../json/fallen.json';
import topReflections from '../json/top10reflections.json';
import featured_streams from '../json/featured-streams.json';
import ReactGA from 'react-ga4';

export default class Homepage extends Component {
    hereFeedTimeoutId;
    hereMessagesTimeoutId;
    reflectionsFeedTimeoutId;
    reflectionsMessagesTimeoutId;
    streamsTimeoutId;
    uiConfigTimeoutId;
    attendanceTimeoutId;
    defaultHereFeedUpdateFrequency = 5000;
    defaultReflectionFeedUpdateFrequency = 5000;
    defaultStreamsUpdateFrequency = 90000;
    minUpdateFrequency = 3000;
    HERE_FEED_TAB = 0;
    REFLECTIONS_FEED_TAB = 1;
    HERE_FEED_PAGE_SIZE = 50;
    DATE_MAX = 2648037074570

    constructor(props) {
        super(props);

        this.state = {
            registerUser: null,
            userLat: '',
            userLng: '',
            location: '',
            currentVideo: '',
            userSelectedVideo: '',
            hereFeedUpdateFrequency: this.defaultHereFeedUpdateFrequency,
            reflectionsFeedUpdateFrequency: this.defaultReflectionFeedUpdateFrequency,
            streamsUpdateFrequency: this.defaultStreamsUpdateFrequency,
            messages: [],
            reflections: [],
            top10reflections: topReflections,
            streams: [],
            viewers: "...",
            lastMessagesMax: null,
            lastReflectionsMax: null,
            activeFeed: 0,
            hereForHashTag: null,
            hereForNameFilter: null,
            moreHereFor: true,
            minHereFor: this.DATE_MAX
        }
        this.onHereForHashTagSelect = this.onHereForHashTagSelect.bind(this);
        this.clearHereForHashTag = this.clearHereForHashTag.bind(this);
        this.getHereFeedUpdates = this.getHereFeedUpdates.bind(this);
        this.loadMoreHereFeed = this.loadMoreHereFeed.bind(this);
    }

    async componentDidMount() {
        this.setState({ reflections: [] });

        let localUser = JSON.parse(localStorage.getItem('musterRegisteredUser'));
        let sessionUser = JSON.parse(window.sessionStorage.getItem('musterRegisteredUser'));

        let registerUser = null;
        if (localUser) {
            registerUser = localUser;

        }

        if (sessionUser) {
            registerUser = sessionUser;

        }

        if ("geolocation" in navigator) {
            this.setUserPosition();
        }

        this.setState({ registerUser }, () => {
            this.startHereFeedUpdates();
        });

        await this.getStreams();

        setTimeout(this.getAttendance.bind(this), 2500);

        this.getUiConfig();
    }

    componentWillUnmount() {
        clearTimeout(this.hereFeedTimeoutId);
        clearTimeout(this.uiConfigTimeoutId);
        clearTimeout(this.hereMessagesTimeoutId);
        clearTimeout(this.streamsTimeoutId);
    }

    setUserPosition() {
        navigator.geolocation.getCurrentPosition((position) => {
            this.setState({ userLat: position.coords.latitude, userLng: position.coords.longitude });

            // Checks to see if use location is already there before running another geocode call
            let userLocation = JSON.parse(localStorage.getItem('musterUserLocation'));
            if (userLocation) {
                this.setState({ location: userLocation });
                return userLocation;
            } else {
                return this.convertUserLatLng(position.coords.latitude, position.coords.longitude);
            }
        });
    }

    setCurrentVideo(videoUrl) {
        localStorage.setItem("userSelectedVideo", videoUrl);

        this.setState({ userSelectedVideo: videoUrl });
    }

    convertUserLatLng(lat, lng) {
        const promise = new Promise((resolve, reject) => {
            var latlng = { lat: lat, lng: lng };

            let location = { city: "", state: "" }
            let city;
            let state;

            var geocoder;
            if (window) {
                geocoder = new window.google.maps.Geocoder();
            }
            geocoder.geocode({ 'location': latlng }, (results, status) => {
                if (status === 'OK') {
                    if (results[0]) {
                        results[0].address_components.map((comp) => {
                            if (comp.types[0] === 'locality') {
                                city = comp.long_name;
                                location.city = comp.long_name;
                            }
                            if (comp.types[0] === 'administrative_area_level_1') {
                                state = comp.short_name;
                                location.state = comp.short_name;
                            }

                            localStorage.setItem("musterUserLocation", JSON.stringify(`${city}, ${state}`));

                            return this.setState({ location: `${city}, ${state}` });
                        })

                        resolve(location);
                    } else {
                        reject("No results found");
                    }
                } else {
                    reject(status);
                }
            });
        });


        return promise;
    }

    async getUiConfig() {
        try {
            const response = await axios({
                method: 'get',
                url: process.env.REACT_APP_UI_CONFIG_URL,
                headers: {
                    'x-functions-key': process.env.REACT_APP_UI_CONFIG_KEY
                }
            });
            const uiConfig = response.data;
            if (uiConfig && uiConfig.reflectionsFeedUpdateFrequency && uiConfig.hereFeedUpdateFrequency && uiConfig.streamsUpdateFrequency) {
                this.setState({
                    hereFeedUpdateFrequency: Math.max(uiConfig.hereFeedUpdateFrequency, this.minUpdateFrequency),
                    reflectionsFeedUpdateFrequency: Math.max(uiConfig.reflectionsFeedUpdateFrequency, this.minUpdateFrequency),
                    streamsUpdateFrequency: Math.max(uiConfig.streamsUpdateFrequency, this.minUpdateFrequency)
                });
            }

        }
        finally {
            const twoMinutesInMilliseconds = 2 * 60 * 1000;
            this.hereFeedTimeoutId = setTimeout(this.getUiConfig.bind(this), twoMinutesInMilliseconds);
        }
    }

    async getAttendance() {
        try {
            const response = await axios({
                method: 'get',
                url: process.env.REACT_APP_ATTENDANCE_URL,
                headers: {
                    'x-functions-key': process.env.REACT_APP_ATTENDANCE_KEY
                }
            });
            let attendance = response.data;
            if (attendance && attendance > 0) {
                const min = Math.floor(Math.random() * 30) + 90;
                attendance = Math.max(min, attendance);
                let formattedAttendance = attendance.toLocaleString('en-US');
                if (formattedAttendance.length > 5) { // Assuming we aren't going to have millions of viewers
                    formattedAttendance = formattedAttendance.substring(0, formattedAttendance.length - 4) + 'K'
                };
                this.setState({
                    viewers: attendance
                });
            }

        }
        finally {
            const fiveMinutesInMilliseconds = 5 * 60 * 1000;
            this.hereFeedTimeoutId = setTimeout(this.getAttendance.bind(this), fiveMinutesInMilliseconds);
        }
    }

    async getStreams() {
        try {
            const response = await axios({
                method: 'get',
                url: process.env.REACT_APP_STREAMS_URL,
                headers: {
                    'Content-Type': 'application/json',
                    'x-functions-key': process.env.REACT_APP_STREAMS_KEY
                }
            });

            if (response
                && response.data
                && response.data.streams
                && Array.isArray(response.data.streams)) {

                this.setState({ streams: response.data.streams, currentVideo: response.data.currentVideo });
            }

        } finally {
            this.streamsTimeoutId = setTimeout(this.getStreams.bind(this), this.state.streamsUpdateFrequency);
        }

    }

    startHereFeedUpdates() {
        if (this.state.registerUser) {
            this.stopHereFeedUpdates();
            this.hereFeedTimeoutId = setTimeout(this.getHereFeedUpdates.bind(this), 500);
        }
    }

    stopHereFeedUpdates() {
        clearTimeout(this.hereFeedTimeoutId);
    }

    startReflectionsFeedUpdates() {
        if (this.state.registerUser) {
            this.stopReflectionsFeedUpdates();
            this.reflectionsFeedTimeoutId = setTimeout(this.getReflectionFeedUpdates.bind(this), 500);
        }
    }

    stopReflectionsFeedUpdates() {
        clearTimeout(this.reflectionsFeedTimeoutId);
    }

    async getReflectionFeedUpdates() {
        try {
            const max = this.state.lastReflectionsMax;
            let reflectionsFeedQuery = `${process.env.REACT_APP_REFLECTIONS_FEED_URL}?count=${15}`;
            if (max) {
                reflectionsFeedQuery = `${reflectionsFeedQuery}&gt=${max}`;
            }
            let response = await axios({
                method: 'get',
                url: reflectionsFeedQuery,
                headers: {
                    'Content-Type': 'application/json',
                    'x-functions-key': process.env.REACT_APP_REFLECTIONS_FEED_KEY
                }
            });

            if (response && response.data
                && response.data.messages
                && Array.isArray(response.data.messages)) {
                this.reflectionsMessagesTimeoutId = setTimeout(this.addReflections.bind(this, response.data.messages), 25);
            }

        } finally {
            if (this.state.activeFeed === this.REFLECTIONS_FEED_TAB) {
                this.reflectionsFeedTimeoutId = setTimeout(this.getReflectionFeedUpdates.bind(this), this.state.reflectionsFeedUpdateFrequency);
            }
        }

    }

    addReflections(reflectionsToAdd) {
        if (reflectionsToAdd && reflectionsToAdd.length > 0) {
            const firstReflection = reflectionsToAdd.shift();
            let reflections = this.state.reflections;
            if (!reflections.some(m => m.id === firstReflection.id)) {
                reflections.unshift(firstReflection);
                if (reflections.length > 25) {
                    reflections.pop();
                }
            }
            this.setState({ reflections }, () => {
                if (reflectionsToAdd.length > 0)
                    this.reflectionsFeedTimeoutId = setTimeout(this.addReflections.bind(this, reflectionsToAdd), 25);
            });
        }
    }

    async loadMoreHereFeed() {
        let { minHereFor } = this.state;
        await this.getHereFeedUpdates(minHereFor);
    }

    async onHereForHashTagSelect(tag,name) {
        this.setState({
            hereForHashTag: tag.replace("#", ""),
            hereForNameFilter: name,
            lastMessagesMax: 0,
            messages: [],
            moreHereFor: true,
            minHereFor: this.DATE_MAX
        }, async () => await this.getHereFeedUpdates());

    }

    async clearHereForHashTag() {
        this.setState({
            hereForHashTag: null,
            hereForNameFilter: null,
            lastMessagesMax: 0,
            messages: [],
            moreHereFor: true,
            minHereFor: this.DATE_MAX
        }, async () => await this.getHereFeedUpdates());
    }

    async getHereFeedUpdates(min = 0) {
        try {
            const max = this.state.lastMessagesMax;
            const currentMin = this.state.minHereFor;
            let hereFeedQuery = new URL(process.env.REACT_APP_HERE_FEED_URL);
            if (min) {
                hereFeedQuery.searchParams.set('lt', min);
            }
            else if (max) {
                hereFeedQuery.searchParams.set('gt', max);
            }
            if (this.state.hereForHashTag) {
                hereFeedQuery.searchParams.set('hashtag', this.state.hereForHashTag);
            }
            const response = await axios({
                method: 'get',
                url: hereFeedQuery.href,
                headers: {
                    'Content-Type': 'application/json',
                    'x-functions-key': process.env.REACT_APP_HERE_FEED_KEY
                }
            });
            if (response && response.data
                && response.data.messages
                && Array.isArray(response.data.messages)) {
                this.hereMessagesTimeoutId = setTimeout(this.addHereMessages.bind(this, response.data.messages), 25);
                this.setState({ lastMessagesMax: response.data.max });
                if ((min && min <= currentMin) || currentMin === this.DATE_MAX) {
                    this.setState({ minHereFor: response.data.min, moreHereFor: response.data.messages.length === this.HERE_FEED_PAGE_SIZE });
                }
            }
        }
        finally {
            if (this.state.activeFeed === this.HERE_FEED_TAB) {
                this.hereFeedTimeoutId = setTimeout(this.getHereFeedUpdates.bind(this), this.state.hereFeedUpdateFrequency);
            }
        }
    }

    addHereMessages(messagesToAdd) {
        if (messagesToAdd && messagesToAdd.length > 0) {
            const firstMessage = messagesToAdd.shift();
            let messages = this.state.messages;
            if (!messages.some(m => m.id === firstMessage.id)) {
                messages.unshift(firstMessage);
                if (messages.length > 400) {
                    messages.pop();
                }
                messages = messages.sort((a, b) => {
                    return a.createdAt - b.createdAt;
                });
            }
            this.setState({ messages }, () => {
                if (messagesToAdd.length > 0)
                    this.hereMessagesTimeoutId = setTimeout(this.addHereMessages.bind(this, messagesToAdd), 25);

                // if the user is near the end of the list then they probably want to scroll so new messages are visible
                // otherwise they have scroll back and new messages should append silently at the end    
                let hereMessagesElement = document.querySelector(".here-messages");
                if ((hereMessagesElement.scrollHeight - hereMessagesElement.clientHeight) - hereMessagesElement.scrollTop < 150) {
                    hereMessagesElement.scrollTop = hereMessagesElement.scrollHeight;
                }
            });
        }
    }

    async registerUser(formData) {
        let { userLat, userLng, location } = this.state;
        let userLocation;
        try {
            userLocation = location;

            if (formData.get('location') !== userLocation) {
                userLocation = formData.get('location');
            }

        } catch (error) {
            console.log("An error has occurred with the users location: ", error);
        }

        let classOf = formData.get('classOf');
        if (!classOf) {
            classOf = null;
        }

        var response = await axios({
            method: 'post',
            url: process.env.REACT_APP_REGISTER_URL,
            data: {
                name: formData.get('name'),
                email: formData.get('email'),
                classOf: classOf,
                location: userLocation,
                userLatLng: { lat: userLat, long: userLng }
            },
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key': process.env.REACT_APP_REGISTER_KEY
            }
        });

        localStorage.removeItem("musterRegisteredUser");
        sessionStorage.removeItem("musterRegisteredUser");

        const rememberMe = formData.get("rememberMe");

        if (rememberMe) {
            localStorage.setItem("musterRegisteredUser", JSON.stringify(response.data));
        } else {
            this.setState({ registerUser: response.data });
            sessionStorage.setItem("musterRegisteredUser", JSON.stringify(response.data))
        }
        this.setState({ registerUser: response.data }, () => {
            this.startHereFeedUpdates();
        });

    }

    getHereMessages = () => {
        let { messages } = this.state;
        let messageTiles = [];

        messages.map((message) =>
            messageTiles.push(
                <CSSTransition key={message.id} timeout={150} classNames="here-message">
                    <HereMessageTile key={message.id} heading={message.hereForMessage} subheading={message.userInfoMessage} hashTags={message.hashTags} onHashTagSelect={this.onHereForHashTagSelect}></HereMessageTile>
                </CSSTransition>
            )
        )

        return messageTiles;
    }

    async sendMessage(formData) {
        let user = this.state.registerUser;
        let hereFor = JSON.parse(formData.get('hereFor'));
        const response = await axios({
            method: 'post',
            url: process.env.REACT_APP_POST_HERE_URL,
            data: {
                userId: user.id,
                userName: user.name,
                classOf: user.classOf,
                location: user.location,
                hereForId: hereFor.id,
                hereForName: hereFor.name,
                hereForClassOf: hereFor.classOf,
                hashTags: hereFor.defaultHashTag
            },
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key': process.env.REACT_APP_POST_HERE_KEY
            }
        });
        ReactGA.event("iamhere",{hashTags: hereFor.defaultHashTag});
        this.addHereMessages([{ ...response.data, createdAt: new Date().getTime() }]);
        let hereMessagesElement = document.querySelector(".here-messages");
        hereMessagesElement.scrollTop = hereMessagesElement.scrollHeight;
    }

    performValidation(formData) {

        let hereFor = formData.get('hereFor');
        let hereForData = hereFor ? JSON.parse(hereFor) : undefined;
        let hashTags = formData.get('hashTags');

        let errors = "";

        if (!hereForData) { errors += "You must select the Aggie you're here for. " };
        if (hashTags && (hashTags.match(/#/g) || []).length > 3) { errors += "You may only use up to three hashtags. " };

        return errors;
    }

    async shareReflection(formData) {
        let user = this.state.registerUser;
        let hereFor = JSON.parse(formData.get('hereFor'));

        formData.append('hereForId', hereFor.id);
        formData.append('hereForName', hereFor.name);
        formData.append('hereForClassOf', hereFor.classOf);

        formData.append('userId', user.id);
        formData.append('userName', user.name);
        formData.append('classOf', user.classOf);
        formData.append('location', user.location);

        formData.delete('hereFor');

        var response = await axios({
            method: 'post',
            url: process.env.REACT_APP_POST_REFLECTION_URL,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-functions-key': process.env.REACT_APP_POST_REFLECTION_KEY
            }
        });

        this.addReflections([response.data]);

        return undefined;
    }

    tabChanged = (activeTab) => {
        this.setState({ activeFeed: activeTab }, () => {
            if (this.state.activeFeed === this.HERE_FEED_TAB) {
                this.stopReflectionsFeedUpdates();
                this.startHereFeedUpdates();
            } else if (this.state.activeFeed === this.REFLECTIONS_FEED_TAB) {
                this.stopHereFeedUpdates();
                this.startReflectionsFeedUpdates();
            }
        });
    }

    logout = () => {
        localStorage.removeItem('musterRegisteredUser');
        sessionStorage.removeItem('musterRegisteredUser');

        this.setState({
            registerUser: null
        });
    }

    render() {
        let { reflections, userLat, userLng, streams, registerUser, currentVideo, userSelectedVideo, viewers, top10reflections, hereForHashTag, moreHereFor, hereForNameFilter } = this.state;
        let clearHereForHashTag = this.clearHereForHashTag;
        let loadMoreHereFeed = this.loadMoreHereFeed;
        //ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search});
        return (
            <>
                <Navigation />
                <div className="home-page">
                    <Row className="here-message-tile-container">
                        <Col xs="12" md="12" lg="8" xl="8">
                            <DefaultVideo setCurrentVideo={(encode) => this.setCurrentVideo(encode)} streamingMusters={streams} user={{ lat: userLat, lng: userLng }} viewers={viewers} videoSrc={userSelectedVideo ? userSelectedVideo : currentVideo}></DefaultVideo>
                        </Col>
                        <Col xs="12" md="12" lg="4" xl="4">
                            <BasicForm
                                containerClass="floating-form-container"
                                formClass="floating-form mt-3"
                                title="Howdy!"
                                content="Join to say here or add reflections for the fallen."
                                hrWidth="150px"
                                onSubmitForm={(formData) => this.registerUser(formData)}
                                registeredUser={this.state.registerUser}
                                fields={[
                                    { type: "text", name: 'name', placeholder: "Name*", required: true },
                                    { type: "number", name: 'classOf', placeholder: "Class (e.g. 1997 or 97)", required: false },
                                    { type: "text", name: 'location', placeholder: "Location", required: false, defaultValue: this.state.location },
                                    { type: "text", name: 'email', placeholder: "Email", required: false }
                                ]}
                                rememberMe={true}
                                submitBtnText="JOIN"
                            >
                                <NavTabs
                                    onTabChanged={this.tabChanged}
                                    containerFlex="column"
                                    containerPadding="px-2 py-3"
                                    tabs={
                                        [
                                            {
                                                flexDirection: "column", icon: true, iconLight: candleStickLight, iconDark: candleStickDark, tabName: "Here", children: [
                                                    <div key="hereFeedMessages" style={{ overflow: 'auto', minHeight: 500, maxHeight: 500 }} className="here-messages">
                                                        {hereForHashTag && hereForNameFilter && <p className="hashtaglist">Showing all "Heres" for<br /><i>{hereForNameFilter}</i> <br /><span className="herelink" style={{cursor: "pointer"}} onClick={clearHereForHashTag}>Clear Filter</span> </p>}
                                                      
                                                        <TransitionGroup>
                                                            <div hidden={moreHereFor} className="seemore"><div style={{ cursor: "pointer" }} className="herelink" onClick={loadMoreHereFeed}>Check For More</div></div>
                                                            {
                                                                this.getHereMessages()
                                                            }
                                                        </TransitionGroup>
                                                       
                                                    </div>,
                                                    <BasicForm key="registerform"
                                                        containerClass="here-message-input-container"
                                                        formClass="d-flex"
                                                        hrClass="d-none"
                                                        registeredUser={this.state.registerUser}
                                                        selectOptions={hereNames}
                                                        onSubmitForm={(formData) => this.sendMessage(formData)}
                                                        fields={[
                                                            { id: 'hereFor', hidden: true, type: "text", name: 'hereFor', required: false },
                                                            { style: 'w-100 d-flex align-items-center m-0 mr-2', inputType: 'select', type: "text", name: 'hereForName', placeholder: "I am Here for...", required: true },
                                                        ]}
                                                        rememberMe={false}
                                                        submitBtn={
                                                            <Button type="submit" className="candle-stick-container">
                                                                <img alt="Candle Stick" src={candleStick} />
                                                            </Button>
                                                        }
                                                    >
                                                    </BasicForm>,


                                                ]
                                            },
                                            {
                                                flexDirection: "column", icon: true, iconLight: frenchHornLight, iconDark: frenchHornDark, tabName: "Reflections", children: [

                                                    <div className="search-icon" key="reflections">
                                                        <a href="/reflections"><img src={searchIcon} alt="Search Reflections" /></a>
                                                    </div>,

                                                    <TransitionGroup key="reflectionsFeedMessages" className="reflection-tiles-container">


                                                        {
                                                            reflections.map((reflection) =>
                                                                <CSSTransition key={reflection.id} timeout={150} classNames="reflection-tile">
                                                                    <ReflectionTile containerSize="fullwidth" messageFrom={reflection.userInfoMessage} messageTo={reflection.hereForMessage} message={reflection.message} rotation={reflection.imageRotation} hashtags={reflection.hashTags} backgroundImage={reflection.imageUrl}></ReflectionTile>
                                                                </CSSTransition>

                                                            )
                                                        }
                                                    </TransitionGroup>,

                                                    registerUser ?

                                                        <ModalForm key="modalform" registeredUser={this.state.registerUser} onSubmit={(formData) => this.shareReflection(formData)}>

                                                        </ModalForm>

                                                        : ''

                                                ]
                                            },
                                        ]}
                                ></NavTabs>
                            </BasicForm>
                            {registerUser ?
                                <div className="posting-as">
                                    <p>Posting as {this.state.registerUser.name} | <span onClick={this.logout}>Logout</span></p>
                                </div>
                                : ''}
                        </Col>
                    </Row>
                    <Row className="bg-grey py-4 px-5 m-0">
                        <NavTabs
                            containerFlex="column"
                            flexDirection="row"
                            tabs={
                                [
                                   {
                                        tabName: "Upcoming Musters",
                                        containerClass: "children-container video-list",
                                        children: [
                                            streams.map((stream) =>
                                                stream.group === "Upcoming" ? <VideoTile setCurrentVideo={() => this.setCurrentVideo(stream.encode)} stream={stream} lat={stream.lat} lng={stream.long}></VideoTile> : ''
                                            )
                                        ]
                                    },
                                    {
                                        tabName: "Previous Musters",
                                        containerClass: "children-container video-list",
                                        children: [
                                            streams.map((stream) =>
                                                stream.group === "Previous" ? <VideoTile key={stream.encode} setCurrentVideo={() => this.setCurrentVideo(stream.encode)} stream={stream} lat={stream.lat} lng={stream.long}></VideoTile> : ''
                                            )
                                        ]
                                    },
                                    {
                                        tabName: "Featured Stories",
                                        containerClass: "children-container video-list",
                                        children: [
                                            featured_streams.map((stream) =>
                                                <VideoTile key={stream.encode} setCurrentVideo={() => this.setCurrentVideo(stream.encode)} stream={stream}></VideoTile>
                                            )
                                        ]
                                    },
                                ]}
                        ></NavTabs>
                    </Row>
                    <Row className="p-5 muster-reflections">
                        <Col xs="12" md="12" lg="4" xl="4" className="d-flex flex-column justify-content-center masonry-layout">
                            <CallToActionTile title="Muster Reflections" content="Share a picture or experience you've had with a fallen Aggie">
                                <PrimaryButton to='/reflections' size="large" uppercase="uppercase">View Reflections Gallery</PrimaryButton>
                            </CallToActionTile>
                        </Col>
                        <Col xs="12" md="12" lg="8" xl="8">
                            <MasonryLayout containerClass="masonry-layout" masonryType="top-ten" reflections={top10reflections}> </MasonryLayout>
                        </Col>
                    </Row>
                    <Row className="p-5 support-muster">
                        <SupportMuster />
                    </Row>
                </div>
                <Footer
                    footerClass="footer"
                />
            </>
        )
    }
}