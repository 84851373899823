import React, { Component } from 'react';

import { Row } from 'reactstrap';
import { ReflectionTile } from '..';


export default class ReflectionMasonry extends Component {

  render() {
    let { reflections, masonryType, className, containerClass } = this.props;

    return (
      <div className={className}>
        {masonryType === "top-ten"
          ?
          <Row className={containerClass}>
            <div className="d-flex flex-column justify-content-center">
              <ReflectionTile postedBy={false} key={reflections[0].id} containerSize="normal" messageFrom={reflections[0].userInfoMessage} rotation={reflections[0].imageRotation ?? 0 } messageTo={reflections[0].hereForMessage} message={reflections[0].message} hashtags={reflections[0].hashTags} backgroundImage={reflections[0].imageUrl}></ReflectionTile>
              <ReflectionTile postedBy={false} key={reflections[1].id} containerSize="normal" messageFrom={reflections[1].userInfoMessage} rotation={reflections[1].imageRotation ?? 0} messageTo={reflections[1].hereForMessage} message={reflections[1].message} hashtags={reflections[1].hashTags} backgroundImage={reflections[1].imageUrl}></ReflectionTile>
            </div>
            <div className="d-flex flex-column">
              <ReflectionTile postedBy={false} key={reflections[2].id} containerSize="normal" messageFrom={reflections[2].userInfoMessage} rotation={reflections[2].imageRotation ?? 0} messageTo={reflections[2].hereForMessage} message={reflections[2].message} hashtags={reflections[2].hashTags} backgroundImage={reflections[2].imageUrl}></ReflectionTile>
              <ReflectionTile postedBy={false} key={reflections[3].id} containerSize="normal" messageFrom={reflections[3].userInfoMessage} rotation={reflections[3].imageRotation ?? 0} messageTo={reflections[3].hereForMessage} message={reflections[3].message} hashtags={reflections[3].hashTags} backgroundImage={reflections[3].imageUrl}></ReflectionTile>
              <ReflectionTile postedBy={false} key={reflections[4].id} containerSize="normal" messageFrom={reflections[4].userInfoMessage} rotation={reflections[4].imageRotation ?? 0} messageTo={reflections[4].hereForMessage} message={reflections[4].message} hashtags={reflections[4].hashTags} backgroundImage={reflections[4].imageUrl}></ReflectionTile>
            </div>
            <div className="d-flex flex-column justify-content-end">
              <ReflectionTile postedBy={false} key={reflections[5].id} containerSize="normal" messageFrom={reflections[5].userInfoMessage} rotation={reflections[5].imageRotation ?? 0} messageTo={reflections[5].hereForMessage} message={reflections[5].message} hashtags={reflections[5].hashTags} backgroundImage={reflections[5].imageUrl}></ReflectionTile>
              <ReflectionTile postedBy={false} key={reflections[6].id} containerSize="normal" messageFrom={reflections[6].userInfoMessage} rotation={reflections[6].imageRotation ?? 0} messageTo={reflections[6].hereForMessage} message={reflections[6].message} hashtags={reflections[6].hashTags} backgroundImage={reflections[6].imageUrl}></ReflectionTile>
              <ReflectionTile postedBy={false} key={reflections[7].id} containerSize="normal" messageFrom={reflections[7].userInfoMessage} rotation={reflections[7].imageRotation ?? 0} messageTo={reflections[7].hereForMessage} message={reflections[7].message} hashtags={reflections[7].hashTags} backgroundImage={reflections[7].imageUrl}></ReflectionTile>
            </div>
            <div className="d-flex flex-column justify-content-center">
              <ReflectionTile postedBy={false} key={reflections[8].id} containerSize="normal" messageFrom={reflections[8].userInfoMessage} rotation={reflections[8].imageRotation ?? 0} messageTo={reflections[8].hereForMessage} message={reflections[8].message} hashtags={reflections[8].hashTags} backgroundImage={reflections[8].imageUrl}></ReflectionTile>
              <ReflectionTile postedBy={false} key={reflections[9].id} containerSize="normal" messageFrom={reflections[9].userInfoMessage} rotation={reflections[9].imageRotation ?? 0} messageTo={reflections[9].hereForMessage} message={reflections[9].message} hashtags={reflections[9].hashTags} backgroundImage={reflections[9].imageUrl}></ReflectionTile>
            </div>
          </Row>
          : false}

        {masonryType === "dynamic"
          ?
          <Row className="d-flex justify-content-center w-100">
            {reflections.map((reflection, itemIndex) => {

              let firstItemInColumn = (reflections.length + 1) / 4;

              let topItem = itemIndex % firstItemInColumn === 0 ? ("top-item " + itemIndex) : '';

              // console.log("Top Item Data: ", itemIndex % firstItemInColumn === 0, itemIndex)

              return <ReflectionTile key={reflection.id} size="large" className={topItem} containerSize="reflections-page" messageFrom={reflection.userInfoMessage} messageTo={reflection.hereForMessage} message={reflection.message} hashtags={reflection.hashTags} backgroundImage={reflection.imageUrl} rotation={reflection.imageRotation}></ReflectionTile>;
            }
            )}
          </Row>
          : false}

      </div>
    );
  }
}