import React, { Component } from 'react';
// import PrimaryButton from './Button';
import eye from '../../assets/eye.png';
// import musterPoint from '../../assets/muster-point-two.png';
import Map from './Map';

// import musters from '../../json/streams.json';

export default class DefaultVideo extends Component {
    constructor(props){
        super(props);

        this.state = {
            map: false,
        }
    }

    toggleMap = () => {
        this.setState({
            map: !this.state.map
        });
    }

    getDefaultVideo = (videoSrc, size) => {

        if(videoSrc){
            return  <iframe  key="MusterDefaultVideo" className={"video " + size} src={`${videoSrc}?title=0&byline=0&portrait=0&transparent=0&autoplay=1&rel=0`} frameBorder="0" title="Muster Live" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" data-ready="true"></iframe> 
        } else {
            return  <iframe className={"video " + size} src={`https://www.youtube.com/embed/9UJVJF4TwC0?title=0&byline=0&portrait=0&transparent=0&rel=0`} frameBorder="0" title="Muster Live" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" data-ready="true"></iframe> 
        }

    }

    render() {
        let { videoSrc, user, streamingMusters, viewers} = this.props;
        let { map } = this.state;

        return (
            <div className="default-video">
                 { this.getDefaultVideo(videoSrc, map ? "map-video" : "large") }
                { 
                    map 
                    ? 
                    <Map setCurrentVideo={(encode) => this.props.setCurrentVideo(encode)} streamingMusters={streamingMusters} user={user} className="map-container-with-video">
                    </Map> : ''
                }
                
                <div className="map-controls mx-2">
                    <div>
                        {/*<PrimaryButton action={() => this.toggleMap()} size="small" margin="mt-3 mr-2" uppercase={false}>{map ? "Show Video" : "View Map"}</PrimaryButton> */}
                    </div>
                    <div className="d-flex pt-2">
                        <p className="map-view-and-muster-count pr-4 my-auto"><img  alt="fish eye" src={eye}></img> {viewers}+ Viewers</p>
                       {/* <p className="map-view-and-muster-count my-auto"><img alt="muster point with star in middle" src={musterPoint}></img> {musters.length + streamingMusters.length} Musters</p> */}
                    </div>
                </div>
            </div>
        );
    }
}