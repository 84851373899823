import React, { Component } from 'react';

import { PrimaryButton, BasicForm} from '../index';
import { Modal, ModalBody } from 'reactstrap';
import darkRedClose from '../../assets/close-btn.png';

import hereNames from '../../json/fallen.json';


export default class ModalFrom extends Component {
    constructor(props){
        super(props);

        this.state = {
            modal: false
        }
    }

    toggleModal = () => {
        this.setState({modal: !this.state.modal})
    }

    componentDidMount() {
        this.setState({ registeredUser: this.props.registeredUser });
    }

    componentWillReceiveProps(newProps) {
        let registeredUser = newProps.registeredUser;
        this.setState({registeredUser});
    }

    
    performValidation(formData) {
        
        let hereFor = formData.get('hereFor');
        let hereForData = hereFor ? JSON.parse(hereFor) : undefined;
        let hashTags = formData.get('hashTags');

        let errors = "";

        if(!hereForData) { errors += "You must select the Aggie you're here for. "};
        if(hashTags && (hashTags.match(/#/g) || []).length > 3) { errors += "You may only use up to three hashtags. "};

        return errors;
    }
    
    render(){
        let { modal } = this.state;
        let { openButtonText, openButtonSize } = this.props;

        return(
            <>
                <PrimaryButton action={() => this.toggleModal()} uppercase={true} size={openButtonSize ? openButtonSize : "large"}>{openButtonText ? openButtonText : "Share A Reflection"}</PrimaryButton>

                <Modal isOpen={modal}>
                    <ModalBody>
                        <button className="close-btn" onClick={() => this.toggleModal()}><img alt="dark red x" src={darkRedClose}></img></button>

                        <BasicForm
                            title="Share A Reflection"
                            uppercase={true}
                            hrWidth="115px"
                            formClass="py-3 px-5 ml-1"
                            selectOptions={hereNames}
                            toggleModal={() => this.toggleModal()}
                            onSubmitForm={(formData) => this.props.onSubmit(formData)}
                            performValidation={(formData) => this.performValidation(formData)}
                            registeredUser={this.state.registeredUser}
                            fields={[
                                { type: "file", name: 'image', required: false },
                                { hidden: true, type: "text", name: 'hereFor', required: false },
                                { inputType: 'select', type: "text", name: 'hereForName', placeholder: "I am Here for...", required: true},
                                { type: "textarea", name: 'message', placeholder: "Thoughts... (maximum 125 characters)", required: false, maxLength: "125"  },
                                { type: "text", name: 'hashTags', placeholder: "#Tags (max 3 hashtags)", required: false }
                            ]}
                            rememberMe={false}
                            submitBtnText="Post"
                        >

                        </BasicForm>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}