import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row } from 'reactstrap';
import classnames from 'classnames';

export default class NavTabs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0
        }
    }

    toggle = (tab) => {
        this.setState({ activeTab: tab })
        if (this.props.onTabChanged) {
            this.props.onTabChanged(tab);
        }
    }

    render() {

        let { tabs, flexDirection, containerPadding, containerFlex } = this.props;
        let { activeTab } = this.state;

        return (
            <div className={"d-flex w-100 flex-" + containerFlex}>
                <Nav tabs className="tabs px-2 py-3">
                    {
                        tabs.map((tab, index) => {
                            return (
                                <NavItem key={index} className="nav-item">
                                    <NavLink
                                        className={classnames({ active: activeTab === index })}
                                        onClick={() => { this.toggle(index); }}
                                    >
                                        {tab.icon ?
                                            <span>
                                                {activeTab === index ? <img className="nav-tab-icon" alt="tab icon" src={tab.iconLight} /> : <img alt="navtab icon" className="nav-tab-icon" src={tab.iconDark} />}
                                            </span> : ''}
                                        {tab.tabName}
                                    </NavLink>
                                </NavItem>
                            )
                        })
                    }
                </Nav>

                <TabContent activeTab={activeTab} className="w-100">
                    {
                        tabs.map((tab, index) => {
                            return (
                                <TabPane key={index} tabId={index}>
                                    <Row className={tab.containerClass + " w-100 " + containerPadding} style={{ flexDirection: (tab.flexDirection ? tab.flexDirection : flexDirection) }}>
                                        {tab.children ? tab.children : <h3>We currently don't have any previous musters.</h3>}
                                    </Row>
                                </TabPane>
                            )
                        })
                    }
                </TabContent>
            </div>
        )
    }
}


// Example of NavTabs
// <NavTabs 
//     tabs={
//         [
//         {tabName: "Musters Worldwide", children: <VideoTile videoSrc="https://youtube.com/embed/9UJVJF4TwC0" city="Austin" state="TX" date="August 29th, 2020" live={true}></VideoTile>}, 
//         {tabName: "Musters Near Me"}, 
//         {tabName: "Previous Musters"}, 
//         {tabName: "The Muster Documentary"}
//         ]}
// >
// </NavTabs>