import React, { Component } from 'react';
import { Navigation, Footer } from '../components/index';

export default class ClassOfSeventyPhotos extends Component {
    render() {
        let classOfSeventyStyle = {
            marginBottom: 20,
        };
        //ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search});
        return (
            <>
                <Navigation />
                <div style={classOfSeventyStyle}>
                    <iframe height="700px" width="100%" title="Aggie Class Of Seventies Photos" src="https://www.aggienetwork.com/muster50thgallery/"></iframe>
                </div>
                <Footer
                    footerClass="footer"
                />
            </>
        );
    }
}