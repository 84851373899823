import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

import musters from '../../json/streams.json';

import LiveMusterMapMarker from '../../assets/live-stream-maroon-marker.png';
import MusterMapPinMaroon from '../../assets/maroon-muster-marker.png';

const MusterMarker = props => {
    let { muster } = props;

    return (
        <div className="map-muster-container" ref={props.ref}>
            <div
                className="map-muster-info-window"
                id={props.infoWindowId}
                style={{ display: 'none', zIndex: 5 }}
            >
                <div className="w-100 d-flex justify-content-end"><button className="map-muster-button" onClick={props.onClick}>X</button></div>
                <h3>{muster.name ? muster.name : muster.title}</h3>
                { muster.link ? <a rel="noopener noreferrer" target="_blank" href={muster.link}>Visit this Muster</a> : <button className="map-marker-button" onClick={props.onStreamingClick}>Watch Now</button> }
            </div>

            <button className="map-muster-button" onClick={props.onClick}>
                <div className="musterMarker">
                    { muster.encode ? 
                    <img style={{zIndex: 1}}  src={LiveMusterMapMarker} alt="muster map marker"></img> 
                    : <img style={{zIndex: 1}}  src={MusterMapPinMaroon} alt="muster map marker"></img> }
                </div>
            </button>
        </div>
    );
}

export default class Map extends Component{
    constructor(props){
        super(props);

        this.state = {
            allMusters: [],
            previousMusterId: '',
            infoWindowOpen: false,
            mobile: false,
            // Default is the United States
            center: {
                lat: 37.09024,
                lng: -95.712891
            },
            zoom: 4,
        }
    }

    componentDidMount(){
        let { streamingMusters } = this.props;
        let allmusters = []

        if(musters) {
            musters.forEach(function(elem) {
                allmusters.push(elem);
            });
        }
        
        if(streamingMusters) {
            streamingMusters.forEach(function(elem) {
                allmusters.push(elem);
            });
        }

        this.setState({allMusters: allmusters});

        window.addEventListener("resize", this.resize.bind(this));
        this.resize();  
    }

    resize() {
        let screenSize = (window.innerWidth <= 760);

        if (screenSize !== this.state.mobile) {
            this.setState({mobile: screenSize});
        }
    }

    openInfoWindow = (infoWindowId) => {
        let { previousMusterId, infoWindowOpen } = this.state;
        let muster = document.getElementById(infoWindowId);
        let previousMuster = document.getElementById(previousMusterId);
        

        if(infoWindowOpen === false && previousMusterId === infoWindowId){
            muster.style.display = "block";
            this.setState({previousMusterId: infoWindowId, infoWindowOpen: true});

        } else if (infoWindowOpen === false) {
            muster.style.display = "block";
            if(previousMuster){
                previousMuster.style.display = "none";
            }

            this.setState({previousMusterId: infoWindowId, infoWindowOpen: true});

        } else {
            muster.style.display = "block"
            previousMuster.style.display = "none";

            this.setState({ previousMusterId: infoWindowId, infoWindowOpen: false});
        }
    }

    render(){
        let { children, className, user } = this.props;
        let { center, allMusters, mobile } = this.state;

        return (
            <div className={className} style={ mobile ? { height: '300px', width: '100%' } : { height: '600px', width: '100%' } }>
                {children}
                <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.REACT_APP_UI_GOOGLE_MAPS_SDK_KEY }}
                    defaultCenter={user.lat && user.lng ? user : center}
                    defaultZoom={4}
                    gestureHandling= {'cooperative'}
                    mapTypeControl= {false}
                    streetViewControl= {true}
                    streetViewControlOptions = {{ position: window.google.maps.ControlPosition.LEFT_CENTER }}
                    fullscreenControl= {true}
                    fullscreenControlOptions = {{ position: window.google.maps.ControlPosition.LEFT_TOP }}
                    zoomControl= {true}
                    zoomControlOptions = {{ position: window.google.maps.ControlPosition.LEFT_MIDDLE}}
                    scaleControl= {true}
                    scaleControlOptions = {{position: window.google.maps.ControlPosition.BOTTOM_LEFT }}
                >
                    {
                        allMusters.map((muster, index) => {
                            if( (muster.lat && muster.long) || ( muster.lat && muster.lng) ){
                                return ( 
                                     <MusterMarker
                                         lat={muster.lat}
                                         lng={muster.lng ? muster.lng : muster.long}
                                         muster={muster}
                                         infoWindowId={index}
                                         onClick={() => this.openInfoWindow(index)}
                                         onStreamingClick={() => this.props.setCurrentVideo(muster.encode)}
                                     />
                                 )
                            } else {
                                console.log("Couldn't add this to the map: ", index, muster)
                                return '';
                            }
                        })
                    }

                </GoogleMapReact>
            </div>
        )
    }
}