import React, { Component } from 'react';
import { Form, FormGroup, Row, Col } from 'reactstrap';
import { Navigation, MasonryLayout, Footer, ModalForm } from '../components/index';
import axios from 'axios';
import Select from 'react-select';
import {
    withRouter
} from 'react-router-dom';
import FloatingActionButtons from '../components/general/FloatingActionButtons';
import PrimaryButton from '../components/general/Button';

class ReflectionsOfTheFallen extends Component {
    constructor(props) {
        super(props);

        let selectedHashTagOption = null;
        if (props.match.params.hashTag) {
            selectedHashTagOption = { label: `#${props.match.params.hashTag}`, value: props.match.params.hashTag }
        }
        this.state = {
            currentVideo: '',
            userSelectedVideo: '',
            hashTag: props.match.params.hashTag,
            selectedHashTagOption,
            reflections: [],
            lastMin: -1,
            isLoadingMore: false,
            registerUser: null,
        }
    }

    async componentDidMount() {
        
        let localUser = JSON.parse(localStorage.getItem('musterRegisteredUser'));
        let sessionUser = JSON.parse(window.sessionStorage.getItem('musterRegisteredUser'));

        let registerUser = null;
        if (localUser) {
            registerUser = localUser;
        }

        if (sessionUser) {
            registerUser = sessionUser;
        }

        this.setState({ 
            registerUser: registerUser 
        });
        
        await this.getCurrentVideo();
        await this.getReflections();
        await this.getHashTags();
    }

    componentWillUnmount() {
        
    }

    async componentWillReceiveProps(newProps) {
        const newHashTag = newProps.match.params.hashTag;
        if (this.state.hashTag !== newHashTag) {
            let selectedHashTagOption = null;
            if (newHashTag) {
                selectedHashTagOption = { label: `#${newHashTag}`, value: newHashTag }
            }
            this.setState({ hashTag: newHashTag, selectedHashTagOption }, async () => {
                await this.getReflections();
            });

        }
    }

    async getCurrentVideo() {
        const response = await axios({
            method: 'get',
            url: process.env.REACT_APP_STREAMS_URL,
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key': process.env.REACT_APP_STREAMS_KEY
            }
        });

        if (response
            && response.data
            && response.data.currentVideo) {

            this.setState({ currentVideo: response.data.currentVideo });
        } 

        let videoUrl = localStorage.getItem('userSelectedVideo');

        if (videoUrl) {
            this.setState({ userSelectedVideo: videoUrl });
        }
    }

    async getHashTags() {
        const hashTags = await axios({
            method: 'get',
            url: process.env.REACT_APP_REFLECTIONS_HASHTAGS_URL,
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key': process.env.REACT_APP_REFLECTIONS_HASHTAGS_KEY
            }
        });
        if (hashTags.data 
            && Array.isArray(hashTags.data) 
            && hashTags.data.length > 0) {
            this.setState({ hashTags: [...hashTags.data.map(h => { return { label: `#${h}`, value: h } })] });
        }
    }

    async getReflections() {
        this.setState({ reflections: [] });

        let url = process.env.REACT_APP_REFLECTIONS_FEED_URL;
        if (this.state.hashTag) {
            url = url + `?hashTag=${encodeURIComponent(this.state.hashTag)}`;
        }
        const reflections = await axios({
            method: 'get',
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key': process.env.REACT_APP_REFLECTIONS_FEED_KEY
            }
        });

        if (reflections && reflections.data 
            && reflections.data.messages
            && Array.isArray(reflections.data.messages)) {
            this.setState({ 
                reflections: [...reflections.data.messages.reverse()],
                lastMin: reflections.data.min
            });
        }
    }

    async loadMoreReflections() {
        
        this.setState({isLoadingMore: true});
        let url = process.env.REACT_APP_REFLECTIONS_FEED_URL + `?lt=${this.state.lastMin}`;
        if (this.state.hashTag) {
            url = url + `&hashTag=${encodeURIComponent(this.state.hashTag)}`;
        }
        const reflections = await axios({
            method: 'get',
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key': process.env.REACT_APP_REFLECTIONS_FEED_KEY
            }
        });

        if (reflections && reflections.data 
            && reflections.data.messages
            && Array.isArray(reflections.data.messages)) {
            this.setState({ 
                reflections: [...this.state.reflections, ...reflections.data.messages.reverse()],
                lastMin: reflections.data.min,
                isLoadingMore: false
            });
        }
        else {
            this.setState({isLoadingMore: false});
        }
    }

    async shareReflection(formData) {
        let user = this.state.registerUser;
        let hereFor = JSON.parse(formData.get('hereFor'));

        formData.append('hereForId', hereFor.id);
        formData.append('hereForName', hereFor.name);
        formData.append('hereForClassOf', hereFor.classOf);

        formData.append('userId', user.id);
        formData.append('userName', user.name);
        formData.append('classOf', user.classOf);
        formData.append('location', user.location);

        formData.delete('hereFor');

        var response = await axios({
            method: 'post',
            url: process.env.REACT_APP_POST_REFLECTION_URL,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-functions-key': process.env.REACT_APP_POST_REFLECTION_KEY
            }
        });
        this.addReflectionToTop(response.data);
    }

    addReflectionToTop(reflectionToAdd) {
        if (reflectionToAdd) {
            let reflections = this.state.reflections;
            if (!reflections.some(m => m.id === reflectionToAdd.id)) {
                reflections.unshift(reflectionToAdd);
            }
            this.setState({ reflections });
        }
    }

    handleChange = (selectedOption, action) => {

        if (action.action === "select-option") {

            this.setState({ selectedHashTagOption: selectedOption });
            this.props.history.push(`/reflections/${selectedOption.value}`);
        } else if (action.action === "clear") {
            this.setState({ selectedHashTagOption: null });
            this.props.history.push("/reflections");
        }

    };


    render() {
        let { reflections, userSelectedVideo, currentVideo, selectedHashTagOption, hashTags, lastMin, isLoadingMore, registerUser } = this.state;

        let showLoadMore = reflections && reflections.length >= 50 && lastMin > 0 && !isLoadingMore;
        return (
            <>
                <Navigation />
                <Row className="reflections-top">
                    <div className="reflections-search">
                        <Form className="d-flex">
                            <FormGroup className="w-100 d-flex align-items-center m-0 mr-2">
                                <Select
                                    className="dynamic-select-container"
                                    classNamePrefix="dynamic-select"
                                    placeholder="Search..."
                                    options={hashTags}
                                    isSearchable
                                    isClearable
                                    value={selectedHashTagOption}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Form>
                    </div>
                    <div className="reflections-share">
                        {
                            registerUser ?

                            <ModalForm registeredUser={this.state.registerUser} onSubmit={(formData) => this.shareReflection(formData)}>
                            </ModalForm>

                            : 
                            <div>
                                <p>Log in on the home page to share reflections.</p>
                            </div>
                        }
                    </div>
                </Row>
                
                <Row className="p-5">
                    <h1 className="primary-color w-100 text-center">Muster Reflections</h1>
                    <MasonryLayout className="reflections-of-the-fallen" reflections={reflections} masonryType="dynamic">
                    </MasonryLayout>
                    <Col md={{size: 4, offset: 4}}>
                    {showLoadMore ? <PrimaryButton type="submit" margin="mt-4" size="large w-100" action={() => this.loadMoreReflections()}>LOAD MORE</PrimaryButton> : <></>}
                    </Col>
                </Row>


                <FloatingActionButtons videoSrc={userSelectedVideo ? userSelectedVideo : currentVideo}></FloatingActionButtons>

                <Footer
                    footerClass="footer"
                />
            </>
        )
    }
}

export default withRouter(ReflectionsOfTheFallen)