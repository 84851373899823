import React, { Component } from 'react';
import { CallToActionTile } from '../index';

export default class SupportMuster extends Component {
    render() {
        return (
            <div id="appeal">
                <div className="musterappealcontent aggienetworkflexcontainer">
                    <CallToActionTile
                        title="Support Musters"
                        heromode={true}
                        content="The Association of Former Students is able to support Aggie Musters around the world through generous gifts from our Century Club donors.">
                        <a href="https://www.aggienetwork.com/give/?sc=02834" target="_blank" className="primary-button px-5 py-2 large uppercase" rel="noopener noreferrer">Help Support Aggie Musters</a>
                    </CallToActionTile>
                </div>
            </div>
        );
    }
}