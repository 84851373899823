import React, { Component } from 'react';
import { Row } from 'reactstrap';
import candleStick from '../../assets/candle-stick.png'
import { Link } from 'react-router-dom'

export default class ReflectionTile extends Component {
    render(){
        let { backgroundImage, messageTo, message, hashtags, messageFrom, containerSize, className, rotation, size = "small", postedBy = true } = this.props;
        const individualHashTags = (hashtags ? hashtags.split(" ") : []).map(h => h.replace("#", ""));
        const hashTagItems = individualHashTags.map(hashTag => <Link key={hashTag} to={`/reflections/${hashTag}`}>#{hashTag} </Link>);

        return (
            <div className={`reflection-tile-container ${containerSize} ${className}`} style={{position: 'relative'}}>
                <div className="justify-content-space-between">
                    
                    <Row className="justify-content-end px-3" style={{zIndex: 2, position: 'absolute', right: 0 }}>
                        <div className="candle-stick-container">
                            <img alt="Candle Stick" src={candleStick}/>
                        </div>
                    </Row>
                    { Math.abs(rotation % 180) === 0 ? 
                    <>
                        <img src={backgroundImage} alt="" style={{transform: `rotate(${rotation}deg)`, height: "auto", width: "100%", position: 'absolute'}} className="gradient"/>
                        <div style={{position: 'absolute', zIndex: 1,  height: "100%", width: "100%", background: "linear-gradient(to bottom,  rgba(255,255,255,0) 0%, #562626 80%)" }}></div>
                    </> :
                    <>
                        <img src={backgroundImage} alt="" style={{transform: `rotate(${rotation}deg)`, height: size === "small" ? "150px" : '250px', width: size === "small" ? "150px" : '250px', position: 'absolute'}} className="gradient"/>
                        <div style={{position: 'absolute', zIndex: 1,  height: "100%", width: "100%", background: "linear-gradient(to bottom,  rgba(255,255,255,0) 0%, #562626 80%)" }}></div>
                    </>
                    }
                    <div className=" d-flex w-100" style={{zIndex: 2}}>
                        <div className="p-1" style={{position: 'absolute', bottom: '10px', left: '5px'}}>
                            <p>{messageTo}: {message}</p>
                            <p>{hashTagItems}</p>
                            { postedBy ? <p className="reflection-author italic">Posted by {messageFrom}</p> : '' }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}