import React, { Component } from 'react';

import { Row } from 'reactstrap';
import { ModalForm, BasicForm } from '../index';

import hereNames from '../../json/fallen.json'
import plus from '../../assets/plus.png';

export default class FloatingActionButtons extends Component {
    render(){
        let { videoSrc } = this.props;

        return (
            <Row className="floating-action-buttons-container">
                <div>
                    { videoSrc ? <iframe className="video small" src={`${videoSrc}?title=0&byline=0&portrait=0&transparent=0&controls=0`} frameBorder="0" title="Funny Cat Videos For Kids" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" data-ready="true"></iframe> : ''}
                </div>
                <div className="d-flex flex-column justify-content-between">
                    <ModalForm
                        openButtonSize="small-circle"
                        openButtonText={<img alt="plus" src={plus}></img>}>
                        <BasicForm
                            title="Share A Reflection"
                            uppercase={true}
                            hrWidth="115px"
                            formClass="py-3 px-5 ml-1"
                            selectOptions={hereNames}
                            onSubmitForm={(formData) => this.shareReflection(formData)}
                            fields={[
                                { type: "file", name: 'image', required: false },
                                { hidden: true, type: "text", name: 'hereFor', required: false },
                                { inputType: 'select', type: "text", name: 'hereForName', placeholder: "I am Here for...", required: true },
                                { type: "textarea", name: 'message', placeholder: "Thoughts...", required: true },
                                { type: "text", name: 'hashTags', placeholder: "#Tags", required: false }
                            ]}
                            rememberMe={false}
                            submitBtnText="POST"
                        >

                        </BasicForm>
                    </ModalForm>
                </div>
            </Row>
        )
    }
}